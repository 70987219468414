import * as styles from "./mainTitle.module.scss"

import React, { memo } from "react"

import PropTypes from "prop-types"

const MainTitle = memo(({ contentTitle }) => {
  return <div className={styles.container}>{contentTitle}</div>
})

MainTitle.propTypes = {
  contentTitle: PropTypes.node,
}

export default MainTitle
