// extracted by mini-css-extract-plugin
export var mainButton = "button-module--mainButton--1Jd0A";
export var containedWhite = "button-module--containedWhite--3orC_";
export var containedYellow = "button-module--containedYellow--2glYk";
export var containedMagenta = "button-module--containedMagenta--wwikr";
export var textWhite = "button-module--textWhite--32Kzj";
export var textMagenta = "button-module--textMagenta--qeDKD";
export var text = "button-module--text--1zFNu";
export var textPurple = "button-module--textPurple--KvjDk";
export var hoverStylesMagenta = "button-module--hoverStylesMagenta--bdCMm";
export var hoverStylesYellow = "button-module--hoverStylesYellow--3SUBw";
export var bold = "button-module--bold--qDb6j";