import React, { useMemo } from "react"
import * as styles from "./rectangle.module.scss"

import PropTypes from "prop-types"

/// USAGE:
/// A Rectangle is a component that renders a rectangle
/// with a border radius and a background [color].
/// This component is positioned absolutely in it's parent center.
/// You can move it around with the props [x] and [y] in the Offset prop.
/// You can also rotate it with the [angle] prop.
/// To change it's dimension you can use the [length] and [thickness] props.

/// IMPORTANT: Parent component MUST be positioned (better if relative)

/// EXAMPLE:
/// <Rectangle
///     offset={{x: 0, y: 0}} // These are pixels values.
///     length={100}          // This is a value in pixels.
///     thickness={10}        // This is a value in pixels.
///     angle={0}             // The angle in degrees, not radians.
///     color="#00A090"
/// />

/**
 * Rounded rectangle component.
 * @param {string} color - The background color of the rectangle.
 * @param {string} animationType - The type of animation to use. "length" | "thickness".
 * @param {number} length - The length of the rectangle.
 * @param {number} thickness - The thickness of the rectangle.
 * @param {number} angle - The angle of rotation in degrees.
 * @param {object} offset - The offset of the rectangle center {x, y}.
 */
const Rectangle = ({
  color,
  length,
  thickness,
  angle = 0,
  offset = { x: 0, y: 0 },
  animationType = "none",
  smoothTransition = false,
}) => {
  const centeredOffsets = useMemo(() => {
    return { x: `calc(${offset.x}px - 50%)`, y: `calc(${offset.y}px - 50%)` }
  }, [offset])

  const positioningStyle = useMemo(
    () => ({
      position: "absolute",
      top: "50%",
      left: "50%",
      transition: smoothTransition ? "all 0.5s ease-in-out" : null,
      transform: `translate(${centeredOffsets.x}, ${centeredOffsets.y}) rotate(${angle}deg)`,
    }),
    [centeredOffsets, angle]
  )

  const animationDuration = useMemo(() => 2, [])
  const initialDelay = useMemo(
    () => -Math.random() * animationDuration,
    [animationDuration]
  )

  const rectangleStyle = useMemo(
    () => ({
      backgroundColor: color,
      width: length,
      height: thickness,
      borderRadius: thickness / 2,
      animationDuration: `${animationDuration}s`,
      animationDelay: `${initialDelay}s`,
      animationIterationCount: "infinite",
      animationTimingFunction: "ease-in-out",
      animationDirection: "alternate",
    }),
    [thickness, length, color, animationDuration, initialDelay]
  )

  return (
    <div style={positioningStyle}>
      <div
        style={rectangleStyle}
        className={
          animationType === "length"
            ? styles.spaghettiAnimation
            : animationType === "thickness"
            ? styles.thickAnimation
            : "none"
        }
      />
    </div>
  )
}

Rectangle.propTypes = {
  color: PropTypes.string,
  animationType: PropTypes.string,
  length: PropTypes.number,
  thickness: PropTypes.number,
  angle: PropTypes.number,
  offset: PropTypes.object,
  smoothTransition: PropTypes.bool,
}

export default Rectangle
