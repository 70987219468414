import * as styles from "./macOsContainer.module.scss"

import React, { memo, useMemo } from "react"

import PropTypes from "prop-types"
import cn from "classnames"

/**
 * MacOS Container
 * @param {string} buttonsColor - The color of the three top dots
 * @param {string} buttonsPosition - The position of the three top dots
 */
const MacOsContainer = ({ buttonsColor, buttonsPosition, children }) => {
  const btnColor = useMemo(
    () =>
      cn(
        styles.macButton,
        buttonsColor === "magenta" ? styles.magenta : styles.purple
      ),
    [buttonsColor]
  )

  const btnPosition = useMemo(
    () => cn(buttonsPosition === "end" ? styles.topBarEnd : styles.topBarStart),
    [buttonsPosition]
  )

  return (
    <div className={styles.mainContainer}>
      <div className={cn(styles.topBar, btnPosition)}>
        <div className={btnColor} />
        <div className={btnColor} />
        <div className={btnColor} />
      </div>
      {children}
    </div>
  )
}

MacOsContainer.propTypes = {
  buttonsColor: PropTypes.string,
  buttonsPosition: PropTypes.string,
  children: PropTypes.node,
}

export default memo(MacOsContainer)
