/* eslint-disable no-unused-vars */
import * as styles from "./mainSubTitle.module.scss"

import React, { memo } from "react"

import PropTypes from "prop-types"

const MainSubTitle = memo(({ children }) => {
  return <div className={styles.container}>{children}</div>
})

MainSubTitle.propTypes = {
  children: PropTypes.node,
}

export default MainSubTitle
