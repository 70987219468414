import * as styles from "./button.module.scss"

import React, { memo, useMemo } from "react"

import PropTypes from "prop-types"
import cn from "classnames"

/**
 * Custom button component
 * @param {'light-magenta'|'light-purple'|'dark-magenta'|'light-yellow'|'text'} variant - Button color variant
 * @param {string} url Link to be redirected to
 * @param {boolean} bold Set to true if the button text should be bold
 * @param {() => string} onClick event handler
 */
const Button = memo(
  ({ variant, children, bold = false, onClick = () => {} }) => {
    const container = useMemo(() => {
      const classNames = [styles.mainButton]

      switch (variant) {
        case "light-yellow":
          classNames.push(styles.containedYellow, styles.textMagenta)
          break
        case "light-magenta":
          classNames.push(
            styles.containedWhite,
            styles.textMagenta,
            styles.hoverStylesMagenta
          )
          break
        case "light-purple":
          classNames.push(styles.containedWhite, styles.textPurple)
          break
        case "dark-magenta":
          classNames.push(styles.containedMagenta, styles.textWhite)
          break
        case "text":
          classNames.push(styles.text, styles.textMagenta)
        default:
          classNames.push(styles.containedWhite, styles.textMagenta)
      }

      if (bold) classNames.push(styles.bold)

      return cn(classNames)
    }, [bold, variant])

    return (
      <button className={container} onClick={onClick}>
        {children}
      </button>
    )
  }
)

Button.propTypes = {
  variant: PropTypes.string.isRequired,
  children: PropTypes.node,
  url: PropTypes.string,
  bold: PropTypes.bool,
  onClick: PropTypes.func,
}

export default Button
